UHH.ready(function(){
 function date_from_research_output(doc) {
  doc=$(doc);
  return doc.find("publicationStatuses publicationStatus[current=true] publicationDate year").text();
 }
 function type_from_research_output(doc) {
  doc=$(doc);
  return doc.find("> type").text();
 }
 function link_from_research_output(doc) {
  retval="";
  doc=$(doc);
  var elec_versions=doc.find("electronicVersion link, electronicVersion doi, electronicVersion file");
  $.each(elec_versions, function(idx, elec_version) {
  elec_version=$(elec_version);
   var oa_logo="";
   if (elec_version.parent().find("accessType[uri='/dk/atira/pure/core/openaccesspermission/open']").length>0) oa_logo=" "+$(".fisbox > .ajax > .logo").first().html();
   if (elec_version.prop("tagName")=="doi") {
    retval+="<div class='doclink'>DOI: <a target='_blank' rel='noopener noreferrer' href='"+elec_version.text()+"'>"+elec_version.text().split("/").slice(3,100).join("/")+"</a>"+oa_logo+"</div>";
   }
   if (elec_version.prop("tagName")=="link") {
    retval+="<div class='doclink'>Link: <a target='_blank' rel='noopener' href='"+elec_version.text()+"'>"+elec_version.text()+"</a>"+oa_logo+"</div>";
   }
   if (elec_version.prop("tagName")=="file") {
    retval+="<div class='doclink'>"+(document.querySelector('html').getAttribute('lang')=="de" ? "Datei" : "File")+": <a target='_blank' rel='noopener' href='"+elec_version.find('fileURL').text()+"'>"+elec_version.find('fileName').text()+"</a>"+oa_logo+"</div>";
   }
  });
  
  return retval;
 }
 function html_from_research_output(doc, locale, endpoint) {
  doc=$(doc);
  let uuid=doc.attr('uuid');
  retval="<div class='oap-result'>"
  retval+="<div class='oap-result-title'>"
  if (endpoint=='hche') {
   retval+="<h5><a href='"+doc.find("info > portalUrl").text()+"' target='_blank'>"+doc.children("title").text()+"</a></h5>"
  }else{
   retval+="<h5><a href='https://www.pod.uni-hamburg.de/detail/"+locale.substr(0,2)+"/publications/title("+uuid+").html' target='_blank'>"+doc.children("title").text()+"</a></h5>"
  }
  if ((subtitle=doc.children("subTitle").text())!="") retval+="<div>"+subtitle+"</div>"
  retval+="</div>"
  retval+="<div class='oap-result-authors'>"
  author_html=[];
  if ($(doc).find("personAssociations authorCollaboration").length > 0) {
   $.each($(doc).find("personAssociations authorCollaboration"), function(idx, collaboration) {
    author_html.push($(collaboration).find("name").text());
   })
  } else {
   $.each($(doc).find("personAssociations").children(), function(idx, author) {
    var person_tag = $(author).find("person");
    var person_uuid="";
    person_uuid=$(author).find("person").attr('uuid');
    var first_name=$(author).find("firstName").text();
    if (first_name.match(/-/)) {
     first_name=first_name.replace(/^\s+|\s+$/g, "").match(/(:?^|-)(.)/g).map((x)=>x).join('.')+'.'; // Kai-Uwe => K.-U.
    } else {
     first_name=first_name.replace(/^\s+|\s+$/g, "").replace(/-/g, " ").split(" ").map(function(x) {return x[0]+"."}).join(" ");
    }
    var last_name=$(author).find("lastName").text();
    var name=last_name+" "+first_name;
    person=$(author).children("person");
    if (person.length>0) {
     if (person_uuid!="") {
       if (endpoint=='hche' && doc.find("info > portalUrl").text().match(/uke/)){
        author_html.push("<a href='https://fis-uke.de/portal/"+locale.substr(0,2)+"/persons/"+"x"+"-"+last_name.toLowerCase()+"("+person_uuid+").html' target='_blank'>"+name+"</a>")
       }else{
        author_html.push("<a href='https://www.pod.uni-hamburg.de/detail/"+locale.substr(0,2)+"/persons/"+first_name.toLowerCase()+"-"+last_name.toLowerCase()+"("+person_uuid+").html' target='_blank'>"+name+"</a>")
       }
     } else {
      author_html.push(name)
     }
    }else{
     author_html.push(name)
    }
   });
  }
  var et_al_threshold=10;
  retval+=author_html.slice(0,et_al_threshold).join(", ");
  if (author_html.length > et_al_threshold) {
   link_id="etal"+Math.floor((Math.random()*99999))
   retval+=", <a role='button' aria-label='Alle Autoren anzeigen' aria-controls='"+link_id+"' aria-expanded='false' class='etallink' href='#'>et al.</a><span id='"+link_id+"' class='etalnames uhhhidden'>"+author_html.slice(et_al_threshold,author_html.length).join(", ")+"</span>";
  }

  retval+="</div>"
  retval+="<div class='oap-result-content'><div class='oap-result-text'><div class='oap-result-source'>"
  rendering=$($(doc).find("rendering").text());

  var cite_nodes=[];
  var type="";
  var position="pre_date";
  $.each(rendering.contents(), function(i, node){
   if (position=="pre_date" && node.classList && node.classList.contains("date")) position="citation";
   if (position=="citation" && node.classList && node.classList.contains("type")) position="type";
   if (position=="citation") {
    if (node.nodeType=="3") {// if textNode
     cite_nodes.push(node.textContent);
    } else if (node.nodeType=="1") { //DOMNode
     cite_nodes.push(node.outerHTML);
    }
   }
   if (position=="type") {
    type=node.outerHTML;
   }
   
  })
  
  retval+=cite_nodes.join("");
  retval+=link_from_research_output(doc);
  //retval+=type;

  retval+="</div></div></div>"
  retval+="</div>"
  return retval;
 }

 // elem = blockquote.dd (the tab)
 function fis_update_research_output(elem) {
  var output_container=$(elem).find(".oap-results");
  output_container.css("opacity", "0.3");
  var uids=$(elem).parents(".fisbox").find(".ajax > .uids").text();
  var endpoint=$(elem).parents(".fisbox").find(".ajax > .endpoint").text();
  if (uids!="") {uids+=","+uids.toLowerCase();} //hack for broken idms
  var types=$(elem).parents(".fisbox").find(".ajax > .types").text();
  var ous=$(elem).parents(".fisbox").find(".ajax > .ous").text();
  var include_children=$(elem).parents(".fisbox").find(".ajax > .include_children").text()=="true"; 
  var locale=$(elem).parents(".fisbox").find(".ajax > .locale").text();
  var q=$(elem).find(".oap-search-string").val();
  var page_size=parseInt($(elem).find(".pagesize option:selected").text());
  var page=parseInt($(elem).find(".oap-navigate-browse .page_current").first().text());
  var offset=(page-1)*page_size;
  var sort_by=$(elem).find(".sort option:selected").attr('data-sort');
  var sort_order=(sort_by=='publicationDate' ? '-' : '');
  var free_keywords=$(elem).parents(".fisbox").find(".ajax > .free_keywords").text();

  request="<?xml version='1.0'?><researchOutputsQuery>"
  request+="<size>"+page_size+"</size>"
  request+="<offset>"+offset+"</offset>"
  request+="<locales><locale>"+locale+"</locale></locales>"
  request+="<renderings><rendering>short</rendering></renderings>"
  request+="<fields>"
  request+="<field>renderings.*</field>"
  request+="<field>title</field>"
  request+="<field>subTitle</field>"
  request+="<field>type.*</field>"
  request+="<field>personAssociations.personAssociation.authorCollaboration.name.text</field>"
  request+="<field>personAssociations.personAssociation.name.firstName</field>"
  request+="<field>personAssociations.personAssociation.name.lastName</field>"
  request+="<field>personAssociations.personAssociation.person</field>"
  request+="<field>publicationStatuses.publicationStatus.publicationDate.year</field>"
  request+="<field>type</field>"
  request+="<field>electronicVersions.electronicVersion.accessType</field>"
  request+="<field>electronicVersions.electronicVersion.doi</field>"
  request+="<field>electronicVersions.electronicVersion.link</field>"
  request+="<field>electronicVersions.electronicVersion.file.fileName</field>"
  request+="<field>electronicVersions.electronicVersion.file.fileURL</field>"
  request+="</fields>"
  request+="<orderings><ordering>"+sort_order+sort_by+"</ordering></orderings>"
  request+="<returnUsedContent>true</returnUsedContent>"
  request+="<navigationLink>true</navigationLink>"
  if (types !="") {
   request+="<typeUris>"
   request+='<typeUri>/dk/atira/pure/researchoutput/researchoutputtypes/'+types.split(',').join('</typeUri><typeUri>/dk/atira/pure/researchoutput/researchoutputtypes/')+'</typeUri>'
   request+="</typeUris>"
  }
  if (uids!="") {
   request+="<forPersons>"
   request+="<ids>"
   request+="<id>"+uids.split(",").join("</id><id>")+"</id>"
   request+="</ids>"
   request+="<idClassification>source</idClassification>"
   request+="</forPersons>"
  } else if (ous!="") {
   request+="<forOrganisationalUnits>"
   request+="<ids>"
   request+="<id>"+ous.split(",").join("</id><id>")+"</id>"
   request+="</ids>"
   request+="<idClassification>pure</idClassification>"
   if (include_children) {
    request+="<hierarchyDepth>10</hierarchyDepth><hierarchyStrategy>PARENTS</hierarchyStrategy>"
   }
   request+="</forOrganisationalUnits>"
  }
  if (free_keywords!="") {
   request+="<freeKeywords>"
   request+="<freeKeyword>"+free_keywords.split(",").join("</freeKeyword><freeKeyword>")+"</freeKeyword>"
   request+="</freeKeywords>"
  }
  request+="<searchString>"+q+"</searchString>"
  request+="</researchOutputsQuery>"
 
  update_oap_navigate_browse($(elem).find(".oap-navigate-browse"), page, parseInt($(elem).find(".oap-navigate-browse .page_count").first().text()) );
 
  $.ajax({
    url: (endpoint=='hche' ? 'https://ml-s-uke-hche.rrz.uni-hamburg.de/index.php' : 'https://www.edit.fis.uni-hamburg.de/ws/api/522/research-outputs?apiKey=aadcae10-0c21-4eeb-a733-a427808c0480'),
//    url: "https://fis-www-test.rrz.uni-hamburg.de/ws/api/522/research-outputs?apiKey=aadcae10-0c21-4eeb-a733-a427808c0480",
    data: request,
    method: "POST",
    contentType: "application/xml",
  }).done(function( data ) {

   var results_total=parseInt($(data).find("result").children("count").text());
   var results=$(data).find("result items").children();
   page_count=Math.ceil(results_total/page_size);
   $(elem).find(".oap-navigate-browse .page_count").text(page_count);

   update_oap_navigate_browse($(elem).find(".oap-navigate-browse"), page, page_count);
   
   html="";

   var old_block="";
   var block_html="";
   $.each(results, function(idx, result) {
    var current_block="";
    if (sort_by=="publicationDate") current_block=date_from_research_output(result);
    if (sort_by=="type") current_block=type_from_research_output(result);
    if (old_block!=current_block) {
     if (block_html!="") html+="<div class='oap-result-block'><header>"+old_block+"</header>"+block_html+"</div>";
     block_html="";
    }
    block_html+=html_from_research_output(result, locale, endpoint);
    old_block=current_block;
   });
   if (old_block=="") {
    html+=block_html;
   } else{
    if (block_html!="") html+="<div class='oap-result-block'><header>"+old_block+"</header>"+block_html+"</div>";
   }
   output_container.html(html);

   $(elem).parents(".dl").first().stop(true, true).animate({paddingBottom: $(elem).parents("blockquote.dd").outerHeight()}, 200); //adapt tab height
   $(elem).parents(".fisbox").find("> .box > .DD").css("height", $(elem).parents("blockquote.dd").outerHeight());

   $(elem).find(".oap-results").css("opacity", "1");
  }).fail(function() {
   output_container.html("<h3>Such fehlgeschlagen / query failed</h3>");
   $(elem).find(".oap-results").css("opacity", "1");
  }).always(function() {
  });
  
 }

 document.querySelectorAll('.pubcontainer').forEach((container)=> {
  if (container.id.replace(/_panel-/, '_tab-')==window.location.hash.replace(/^#/, '') || container.matches('.init')) {
    update_tab(container);
  }
 });

// ------------------------------------------Projects-----------------------------------------------
 function title_from_project(doc){
  doc=$(doc);
  retval=$(doc).find("title").text();
  if (retval=="") retval=$(doc).find("shortTitle").text();
  if (retval=="") retval="No title"
  return retval;
 }
 function date_from_project(doc) {
  doc=$(doc);
  start=doc.find("> period startDate").text();
  end=doc.find("> period endDate").text();
  retval="";
  if (start!="") {
   retval=start.slice(8,10)+"."+start.slice(5,7)+"."+start.slice(0,4);
  }
  if (end!="") {
   retval+=" - "+end.slice(8,10)+"."+end.slice(5,7)+"."+end.slice(0,4);
  }
  return retval;
 }
 function description_from_project(doc) {
  doc=$(doc);
  var retval=$(doc).find("description[typeUri='/dk/atira/pure/upmproject/descriptions/laymansdescription']").text();
  if (retval=="") retval=$(doc).find("description[typeUri='/dk/atira/pure/upmproject/descriptions/projectdescription']").text();
  return retval;
 }
 function link_from_project(doc) {
  retval="";
  doc=$(doc);
  var links=doc.find("links link url");
  $.each(links, function(idx, link) {
   retval+="<div class='doclink'>Link: <a target='_blank' rel='noopener' href='"+$(link).text()+"'>"+$(link).text()+"</a></div>";
  });
  return retval;
 }
 function html_from_project(doc) {
  doc=$(doc);
  doc.find("title");
  var retval="<div class='oap-result'>";
  retval+="<div class='oap-result-title'>";
  retval+="<h5>"+title_from_project(doc)+"</h5>";
  retval+="<div>"+date_from_project(doc)+"</div>";
  retval+="</div>";

  retval+="<div class='oap-result-authors'>";
  participant_html=[]
  $.each($(doc).find("participants").children(), function(idx, participant) {
   var name=$(participant).find("lastName").text()+" ";
   name+=$(participant).find("firstName").text().replace(/^\s+|\s+$/g, "").split(" ").map(function(x) {return x[0]+"."}).join(" ");
   person=$(participant).children("person");
   if (person.length>0) {
    //participant_html.push("<a href='"+person.attr("uuid")+"'>"+$(participant).find("lastName").text()+" "+$(participant).find("firstName").text()+"</a>")
    participant_html.push(name);
   }else{
    participant_html.push(name);
   }
  })
  retval+=participant_html.join(", ");
  retval+="</div>";
  
  retval+="<div class='oap-result-content'><div class='oap-result-text'><div class='oap-result-source'>";
  retval+=description_from_project(doc);
  retval+=link_from_project(doc);
  retval+="</div></div></div>";
  

  retval+="</div>";
  return retval
 }

 function fis_update_projects_output(elem) {
  var output_container=$(elem).find(".oap-results");
  output_container.css("opacity", "0.3");
  var uids=$(elem).parents(".fisbox").find(".ajax > .uids").text();
  var endpoint=$(elem).parents(".fisbox").find(".ajax > .endpoint").text();
  var include_children=$(elem).parents(".fisbox").find(".ajax > .include_children").text()=="true";

  if (uids!="") {uids+=","+uids.toLowerCase();} //hack for broken idms
  var ous=$(elem).parents(".fisbox").find(".ajax > .ous").text();
  var locale=$(elem).parents(".fisbox").find(".ajax > .locale").text();
  var q=$(elem).find(".oap-search-string").val();
  var page_size=parseInt($(elem).find(".pagesize option:selected").text());
  var page=parseInt($(elem).find(".oap-navigate-browse .page_current").first().text());
  var offset=(page-1)*page_size;
  var sort_by=$(elem).find(".fis-sort-by li[aria-selected=true]").attr("data-sort");
  var sort_order="";

  request="<?xml version='1.0'?><projectsQuery>"
  request+="<size>"+page_size+"</size>"
  request+="<offset>"+offset+"</offset>"
  request+="<locales><locale>"+locale+"</locale></locales>"
  request+="<orderings><ordering>-startDate</ordering></orderings>"
  if (uids!="") {
   request+="<forPersons>"
   request+="<ids>"
   request+="<id>"+uids.split(",").join("</id><id>")+"</id>"
   request+="</ids>"
   request+="<idClassification>source</idClassification>"
   request+="</forPersons>"
  } else if (ous!="") {
   request+="<forOrganisationalUnits>"
   request+="<ids>"
   request+="<id>"+ous.split(",").join("</id><id>")+"</id>"
   request+="</ids>"
   request+="<idClassification>pure</idClassification>"
   if (include_children) {
    request+="<hierarchyDepth>10</hierarchyDepth><hierarchyStrategy>PARENTS</hierarchyStrategy>"
   }
   request+="</forOrganisationalUnits>"
  }
  request+="<searchString>"+q+"</searchString>"
  request+="</projectsQuery>"
  
  update_oap_navigate_browse($(elem).find(".oap-navigate-browse"), page, parseInt($(elem).find(".oap-navigate-browse .page_count").first().text()) );

  $.ajax({
    url: (endpoint=='hche' ? 'https://ml-s-uke-hche.rrz.uni-hamburg.de/index.php' : 'https://www.edit.fis.uni-hamburg.de/ws/api/522/projects?apiKey=aadcae10-0c21-4eeb-a733-a427808c0480'),
//    url: "https://fis-www-test.rrz.uni-hamburg.de/ws/api/522/projects?apiKey=aadcae10-0c21-4eeb-a733-a427808c0480",
    data: request,
    method: "POST",
    contentType: "application/xml",
  }).done(function( data ) {

   var results_total=parseInt($(data).find("result").children("count").text());
   var results=$(data).find("result items").children();
   page_count=Math.ceil(results_total/page_size);
   $(elem).find(".oap-navigate-browse .page_count").text(page_count);

   update_oap_navigate_browse($(elem).find(".oap-navigate-browse"), page, page_count);
   
   html="";
   $.each(results, function(idx, result) {
    html+=html_from_project(result);
   });

   output_container.html(html);
   $(elem).parents(".dl").first().stop(true, true).animate({paddingBottom: $(elem).parents("blockquote.dd").outerHeight()}, 200); //adapt tab height
   $(elem).parents(".fisbox").find("> .box > .DD").css("height", $(elem).parents("blockquote.dd").outerHeight());

   $(elem).find(".oap-results").css("opacity", "1");
  }).fail(function() {
   output_container.html("<h3>Such fehlgeschlagen / query failed</h3>");
   $(elem).find(".oap-results").css("opacity", "1");
  }).always(function() {
  });
 }

// ------------------------------------------Activities-----------------------------------------------

 function start_date_from_activity(doc){
  return doc.querySelector('period startDate year') ? doc.querySelector('period startDate year').textContent : 'N/A';
 };
 function type_from_activity(doc){
  return doc.querySelector(':scope > type') ? doc.querySelector(':scope > type').textContent : 'N/A';
 };


 function date_from_activity(doc){
  retval='';
  retval+=doc.querySelector('period startDate').textContent;
 }

 function html_from_activity(doc){
  rendering = (new DOMParser()).parseFromString(doc.querySelector('rendering').textContent, "text/xml");
  var retval="<div class='oap-result'>";
  retval+="<div class='oap-result-title'>";
  retval+="<h5>"+doc.querySelector('title').textContent+"</h5>";
  if (doc.querySelector(':scope > type')) retval+='<div>'+doc.querySelector(':scope > type').textContent+'</div>';
  if (rendering.querySelector('.dates')) retval+="<div>"+rendering.querySelector('.dates').innerHTML.replace(/→/, (document.documentElement.lang=='de' ? 'bis' : 'to'))+"</div>";
  retval+="</div>";

  if (rendering.querySelector('.rendering_attendance p')) {
   retval+="<div class='oap-result-authors'>";
   retval+=rendering.querySelector('.rendering_attendance p').innerHTML;
   retval+="</div>";
  }

  retval+="<div class='oap-result-content'><div class='oap-result-text'><div class='oap-result-source'>";
  if (doc.querySelector('description value text')) retval+='<p>'+doc.querySelector('description value text').textContent+'</p>';
  retval+="</div></div></div>";
  

  retval+="</div>";
  return retval;
 }
 function fis_update_activities_output(elem) {
  var output_container=$(elem).find(".oap-results");
  output_container.css("opacity", "0.3");
  let uids=$(elem).parents(".fisbox").find(".ajax > .uids").text();
  let endpoint=$(elem).parents(".fisbox").find(".ajax > .endpoint").text();
  let include_children=$(elem).parents(".fisbox").find(".ajax > .include_children").text()=="true";

  if (uids!="") {uids+=","+uids.toLowerCase();} //hack for broken idms
  let ous=$(elem).parents(".fisbox").find(".ajax > .ous").text();
  let locale=$(elem).parents(".fisbox").find(".ajax > .locale").text();
  let q=$(elem).find(".oap-search-string").val();
  let page_size=parseInt($(elem).find(".pagesize option:selected").text());
  let page=parseInt($(elem).find(".oap-navigate-browse .page_current").first().text());
  let offset=(page-1)*page_size;
  let free_keywords=$(elem).parents(".fisbox").find(".ajax > .free_keywords").text();
  let sort_by=$(elem).find(".sort option:selected").attr('data-sort');
  let sort_order=(sort_by=='startDate' ? '-' : '');

  console.log(free_keywords);

  request="<?xml version='1.0'?>"
  request+="<activitiesQuery>"
  request+="  <size>"+page_size+"</size>"
  request+="  <offset>"+offset+"</offset>"
  request+="  <locales><locale>"+locale+"</locale></locales>"
  request+="  <renderings>"
  request+="    <rendering>short</rendering>"
  request+="  </renderings>"
  request+="  <searchString>"+q+"</searchString>"
  request+="  <fields>"
  request+="    <field>descriptions.description.value.text</field>"
  request+="    <field>title.text</field>"
  request+="    <field>type.term.text</field>"
  request+="    <field>period.*</field>"
  request+="    <field>renderings.*</field>"
  request+="  </fields>"
  request+="  <orderings><ordering>"+sort_order+sort_by+"</ordering></orderings>"
  request+="  <returnUsedContent>true</returnUsedContent>"
  request+="  <navigationLink>true</navigationLink>"
  if (uids!="") {
   request+="  <forPersons>"
   request+="    <ids>"
   request+="      <id>"+uids.split(",").join("</id><id>")+"</id>"
   request+="    </ids>"
   request+="    <idClassification>source</idClassification>"
   request+="  </forPersons>"
  } else if (ous!="") {
   request+="  <forOrganisationalUnits>"
   request+="    <ids>"
   request+="      <id>"+ous.split(",").join("</id><id>")+"</id>"
   request+="    </ids>"
   request+="    <idClassification>pure</idClassification>"
   if (include_children) {
    request+="<hierarchyDepth>10</hierarchyDepth><hierarchyStrategy>PARENTS</hierarchyStrategy>"
   }
   request+="    </forOrganisationalUnits>"
  }
  if (free_keywords!="") {
   request+="<freeKeywords>"
   request+="<freeKeyword>"+free_keywords.split(",").join("</freeKeyword><freeKeyword>")+"</freeKeyword>"
   request+="</freeKeywords>"
  }
  request+="</activitiesQuery>"

  update_oap_navigate_browse($(elem).find(".oap-navigate-browse"), page, parseInt($(elem).find(".oap-navigate-browse .page_count").first().text()) );

  
  //fetch("https://fis-www-test.rrz.uni-hamburg.de/ws/api/522/activities?apiKey=aadcae10-0c21-4eeb-a733-a427808c0480", {
  fetch(endpoint=='hche' ? 'https://ml-s-uke-hche.rrz.uni-hamburg.de/index.php' : "https://www.edit.fis.uni-hamburg.de/ws/api/522/activities?apiKey=aadcae10-0c21-4eeb-a733-a427808c0480", {
   method: 'POST',
   headers:{'content-type': 'application/xml'},
   body: request
  }).then(r => r.text()).then((text) => {
   xml = (new DOMParser()).parseFromString(text, "text/xml");

  

   let results_total=parseInt(xml.querySelector('result count').textContent);
   let page_size=parseInt(xml.querySelector('pageInformation size').textContent);
   let page_count=Math.ceil(results_total/page_size);
   $(elem).find(".oap-navigate-browse .page_count").text(page_count);
   update_oap_navigate_browse($(elem).find(".oap-navigate-browse"), page, page_count);

 
   window.xyz=xml;
   html='';

   let old_block="";
   let block_html="";
   xml.querySelectorAll('items > *').forEach((activity) => {
    let current_block="";
    if (sort_by=="startDate") current_block=start_date_from_activity(activity);
    if (sort_by=="type") current_block=type_from_activity(activity);
    if (old_block!=current_block) {
     html+="<div class='oap-result-block'><header>"+current_block+"</header>";
    }

    html+=html_from_activity(activity);

    if (old_block!=current_block) {
     html+="</div>";
     old_block=current_block;
    }
   });
   output_container.html(html);
   $(elem).parents(".dl").first().stop(true, true).animate({paddingBottom: $(elem).parents("blockquote.dd").outerHeight()}, 200); //adapt tab height
   $(elem).parents(".fisbox").find("> .box > .DD").css("height", $(elem).parents("blockquote.dd").outerHeight());

   $(elem).find(".oap-results").css("opacity", "1");
  }).catch((error)=> {
   output_container.html("<h3>Such fehlgeschlagen / query failed</h3>");
   $(elem).find(".oap-results").css("opacity", "1");
  });
 }

// ---------------------------------------------------------Misc------------------------------------------------
 // update page selector
 function update_oap_navigate_browse(elem, page, page_count) {
   if (page<2) {
    $(elem).find("a.back").replaceWith("<span class='inactive back'></span>");
   } else {
    $(elem).find("span.back").replaceWith("<a href='#' class='back'></span>");
   }
   if (page>=page_count) {
    $(elem).find("a.next").replaceWith("<span class='inactive next'></span>");
   } else {
    $(elem).find("span.next").replaceWith("<a href='#' class='next'></span>");
   }
 }

 $(".fisbox").on("click", "a.etallink", function(e) {
  e.preventDefault();
  $(this).nextAll(".etalnames").removeClass("uhhhidden").focus().removeAttr("tabindex");
  $(e.target).parents(".dl").first().stop(true, true).animate({paddingBottom: $(e.target).parents("blockquote.dd").outerHeight()}, 200);
  $(this).remove();
 });


 function update_tab(tab) {
  tab=$(tab);
  if (tab.hasClass("research-outputs")) {
   fis_update_research_output(tab);
  }else if ($(tab).hasClass("projects")) {
   fis_update_projects_output(tab);
  }else if ($(tab).hasClass("activities")) {
   fis_update_activities_output(tab);
  }
 }

 document.querySelectorAll(".fisbox button[role=tab]").forEach((button)=>{
  button.addEventListener('click', (e) => {
   update_tab(document.getElementById(e.target.id.replace(/_tab-/, "_panel-")));
  });
 });

 var dt = 150;
 $('.openaccess .pagesize, .openaccess .sort').on('change', function() {update_tab($(this).parents(".pubcontainer"));});
 $("input.oap-search-submit").on('mouseup touchend keypress', function(ev){ $(this).parents(".pubcontainer").find(".oap-navigate-browse .page_current").text("1"); update_tab($(this).parents(".pubcontainer"))  });
 $("input.oap-search-string").on('keyup', function(ev){ if (ev.keyCode == 13) {$(this).parents(".pubcontainer").find(".oap-navigate-browse .page_current").text("1"); update_tab($(this).parents(".pubcontainer"))} });
 $(".oap-navigate-browse").on('click touchend keypress', "a", function(ev){
  ev.preventDefault();
  page_current=$(this).parents(".pubcontainer").find(".oap-navigate-browse .page_current");
  if ($(this).hasClass("next")) {
   page_current.text(parseInt(page_current.first().text())+1);
  } else if ($(this).hasClass("back")) {
   page_current.text(parseInt(page_current.first().text())-1);
  }
  update_tab($(this).parents(".pubcontainer"));
 });
})
