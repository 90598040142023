UhhDialog = function() {
 let dialogs = []; // keep track of stacked dialogs (disable event endlers on all but the topmost dialog)
 let ignore_focus_events = false; // .focus() calls the onFocus event handlers. ignore_focus_events prevents them from acting

 function attemptFocus(elem) {
  try {
   ignore_focus_events=true;
   elem.focus();
   ignore_focus_events=false;
  } catch(e) {};
 }

 this.closeTopmostDialog = () => {
  dialogs[dialogs.length-1].close(); 
 }

 this.showModal = (title, content, buttons, default_button=-1, dlg_size='small') => {
  let dialog = {};
  dialog.focused_before_opened=document.activeElement;
  dialog.topmost_dlg=true; // a newly created dialog is always the topmost dialog
  let last_focused_elem_in_dlg = undefined;
  let focussable_elems_in_dlg = [];
  let trapFocus = (evnt) => {
   if (ignore_focus_events || !dialog.topmost_dlg) {return};
   if (dialog.dialog_node.contains(evnt.target)) {
    last_focused_elem_in_dlg = evnt.target;
   }else{
    attemptFocus(focussable_elems_in_dlg[0]);
    if (last_focused_elem_in_dlg == document.activeElement) {
     attemptFocus(focussable_elems_in_dlg[focussable_elems_in_dlg.length-1]);
    }
    last_focused_elem_in_dlg = document.activeElement;
   }
  }
  let closeModal = ()=>{
   document.removeEventListener('focus', trapFocus, true);
   document.removeEventListener("keyup", closeOnEsc)
   dialog.modal_node.remove();
   dialogs.pop();
   if (dialogs[dialogs.length-1]) {
    dialogs[dialogs.length-1].topmost_dlg=true;
   }else{
    document.querySelector('body').style.overflowY='';
   }
   dialog.focused_before_opened.focus();
  }
  // must be declared inside of showModal so that each dialog has its own closeOnEsc function. otherwise removeEventListener would not work
  let closeOnEsc = (evnt)=>{
   if (!dialog.topmost_dlg) {return};
   if (evnt.key=='Escape') {
    evnt.stopPropagation();
    closeModal();
   }
  }


  let dlg_id=Math.floor(Math.random()*99999999); // create unique ids when multiple dialogs displayed
  let dlg_html="<div class='modal_overlay'><div tabindex='0'></div><div class='dialog dlg_size_"+dlg_size+"' id='dlg_"+dlg_id+"' role='alertdialog' aria-modal='true' aria-labelledby='dlg_"+dlg_id+"_title' aria-describedby='dlg_"+dlg_id+"_content'><div class='title' id='dlg_"+dlg_id+"_title'>"+title+"</div><div id='dlg_"+dlg_id+"_content' class='content'>"+content+"</div><div class='buttons'>"
  buttons.forEach((btn, idx) => {
   dlg_html+="<button "+(idx==default_button ? "class='default_button' " : "")+"id='dlg_"+dlg_id+"_btn_"+idx+"'>"+btn[0]+"</button>"
   btn[0]='dlg_'+dlg_id+'_btn_'+idx;
  });
  dlg_html+="</div></div><div tabindex='0'></div></div>";
  document.querySelector('body').style.overflowY='hidden';
  document.querySelector('body').insertAdjacentHTML('beforeend', dlg_html);
  dialog.modal_node = document.querySelector('body').lastElementChild;
  dialog.dialog_node = dialog.modal_node.querySelector('.dialog');
  buttons.forEach((btn) => {
   document.getElementById(btn[0]).addEventListener('click', btn[1]);
  });
  // handle focus
  focussable_elems_in_dlg=[...dialog.dialog_node.querySelectorAll('a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')].filter(el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
  document.addEventListener('focus', trapFocus, true);
  if (dialog.dialog_node.querySelector('button.default_button')) {
   attemptFocus(dialog.dialog_node.querySelector('button.default_button'));
  } else {
   attemptFocus(focussable_elems_in_dlg[0]);
  }
  // handle ESC input
  document.addEventListener("keyup", closeOnEsc)
  dialogs.forEach((d)=>{d.topmost_dlg=false});
  dialog.close=closeModal;
  dialogs.push(dialog);
  return dialog;
 }
};

window.UhhDialog=new UhhDialog();
