document.addEventListener('DOMContentLoaded', ()=>{
 if (document.querySelector('.db_table')) {(()=>{
 
  function do_oap_query(oap) {
   oap_query={_text: oap.querySelector('input[type=text]').value}
   oap.querySelectorAll('select').forEach((select) => {
    if (select.firstChild.textContent!=select.value) {
     oap_query[select.firstChild.textContent]=select.value;
    }
   })
   //console.log(oap_query);
 
   oap.querySelectorAll('.oap-result').forEach((result)=>{
    oap_result={_text: (Array.from(result.querySelectorAll('h2, .tags .text, .tags .tag, .oap-result-abstract')).map((node)=>{return node.textContent;}).join('$'))};
    result.querySelectorAll('.oap-result-tags').forEach((tags) => {
     tags.querySelectorAll('a.tag').forEach((tag)=>{
      if (!oap_result[tags.querySelector('h3').textContent]) oap_result[tags.querySelector('h3').textContent]=[]
      oap_result[tags.querySelector('h3').textContent].push(tag.textContent)
     });
    });
    //console.log(oap_result);
 
    hide=Object.keys(oap_query).some((key) => {
     if (key=="_text") {
      if (!oap_result[key].toLowerCase().match(oap_query[key].toLowerCase())) return true;
     }else{
      if (!oap_result[key].includes(oap_query[key])) return true;
     }
    });
    let result_count=0;
    if (hide){ result.style.display='none'; }else{ result.style.display='block'; result_count++}
 
   });
  }
 
 
  let table_data=[];
  let table_img_position=0; // identifies the column that contains images (if present)
  let lang=document.querySelector('html').getAttribute('lang');
  let translations={reset_form: {de: 'Filter zurücksetzen', en: 'Reset form'}, filter_by: {de: 'Filtern nach', en: 'Filter by'}, q_desc: {de: 'Mit der Volltextsuche können Sie ganz spezifisch nach Elementen suchen.', en: 'The full text search helps you find specific items.'}, search_term: {de: 'Suchbegriff', en: 'Search term'}, search: {de: 'Suche', en: 'Search'}};
 
  document.querySelectorAll('.db_table').forEach((tab, tab_idx)=>{
   tab.style.display='none';
   tab.querySelectorAll('th').forEach((th, th_idx) => {
    table_data[th_idx]={ label: th.getAttribute('data-label')=='true', head: th.textContent, data: [] };
   })
   tab.querySelectorAll('tbody tr').forEach((tr, tr_idx) => {
    tr.querySelectorAll('td').forEach((td, td_idx) => {
     if (td_idx<tr.querySelectorAll('td').length-1 && td.querySelector('img')) table_img_position=td_idx;
     table_data[td_idx]['data'].push(td.innerHTML);
     if(table_data[td_idx].label==true){
      if (table_data[td_idx]['options']==undefined) table_data[td_idx]['options']={};
      td.textContent.split(';').map((x)=>x.trim()).forEach((tag)=>{
       table_data[td_idx]['options'][tag]=true;
      });
     }
    });
   });
   console.log(table_data);
   console.log(table_img_position);
 
   html="<div class='openaccess datenbanken equalitydb'>";
 
   html+="<form class='oap-search'>";
   html+=" <div class='oap-search-text'> <h2>"+translations['search'][lang]+"</h2><p>"+translations['q_desc'][lang]+"</p> </div>";
   html+=" <div class='oap-search-search'>";
   html+="  <div class='oap-search-input' style='white-space: nowrap;'>";
   html+="   <input type='text' class='oap-search-string input-x has-x' aria-label='"+translations['search_term'][lang]+"'>";
   html+="   <input type='submit' name='commit' value='' class='oap-search-submit' tabindex='-1' aria-hidden='true'>";
   html+="  </div>";
   html+=" </div>";
   html+=" <div class='oap-search-search'>";
   html+="  <div class='oap-search-select'>";
 
   table_data.forEach((data)=>{
    if (options=data['options']){
     html+="<select aria-label='"+data.head+"'>";
      html+="<option>"+data.head+"</option>";
      Object.keys(options).sort((a,b)=>a.toLowerCase().localeCompare(b.toLowerCase())).forEach((option)=>{
       html+="<option>"+option+"</option>";
      });
     html+="</select>";
    }
   })
 
   html+="  </div>";
   html+="  <div class='oap-search-button'><button type='reset' class='resetbutton'>"+translations['reset_form'][lang]+"</button></div>";
   html+=" </div>";
   html+="</form>";
 
   html+="<div class='oap-navigate'></div><div class='oap-results img640'>";
 
   for (let i=0; i<table_data[0]['data'].length; i++){
    html+="<div class='oap-result'>";
     html+="<div class='oap-result-title'><h2>"+table_data[0]['data'][i]+"</div>";
     html+="<div class='oap-result-content'>";
     if (table_img_position!=0 && table_data[table_img_position].data[i]!='') {
      html+="<div class='oap-result-image'>"+table_data[table_img_position].data[i]+"</div>";
     }
      html+="<div class='oap-result-text'>";
      for (let j=1; j<table_data.length-1; j++) {
       if (j==table_img_position) continue;
       html+="<div class='oap-result-tags'>";
        html+="<h3>"+table_data[j].head+"</h3>";
        if (table_data[j].label) {
         html+="<ul class='tags'>";
          table_data[j].data[i].split(';').map((x)=>x.trim()).forEach((tag)=>{
           html+="<li><a href='#' class='tag' role='button' aria-label='"+translations['filter_by'][lang]+": "+tag+"'><span aria-hidden='true'>"+tag+"</span></a></li>";
          })
         html+="</ul>";
        }else{
         html+="<div class='tags'><span class='text'>"+table_data[j].data[i]+"</span></div>";
        }
       html+="</div>";
      };
      html+="<div class='oap-result-abstract'>"+table_data[table_data.length-1].data[i]+"</div>";
      html+="</div>";
     html+="</div>";
    html+="</div>";
   }
 
   html+="</div></div>";
 
   tab.insertAdjacentHTML('afterend', html);
   oap=tab.nextElementSibling;
 
   oap.querySelector('.oap-search-input input[type=text]').addEventListener('keyup', (e)=>{
    do_oap_query(oap)
   });
 
   oap.querySelectorAll('.oap-search-select select').forEach((select) => {select.addEventListener('change', (e)=>{
    do_oap_query(oap);
   })})
 
   oap.querySelector('button.resetbutton').addEventListener('click', (e)=>{
    oap.querySelector('input[type=text]').value='';
    oap.querySelectorAll('.oap-search select').forEach((select)=>{  select.value=select.firstChild.textContent; })
    do_oap_query(oap);
   });
 
   oap.querySelectorAll('a.tag').forEach((tag)=>{
    tag.addEventListener('click', (e)=>{
     e.preventDefault();
     attrib=e.target.closest('.oap-result-tags').querySelector('h3').textContent;
     val=e.target.textContent;
     oap.querySelectorAll('select').forEach((select) => {    if (select.firstChild.textContent==attrib) select.value=val;   });
     do_oap_query(oap);
    });
   });
 
 
  });
 
 })();}
}, false);
