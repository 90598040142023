function b64e(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
    }));
}

function b64d(str) {
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

$(function() {
 $(b64d("LnVuaXF1ZV90aWNrZXQgaW5wdXQudGlja2V0")).each(function(a,b){$(b).val($(b).val().split("").reverse().join(""))});
 $(b64d("Zm9ybS51bmlxdWVfdGlja2V0")).on("submit", function(e) {
  $(b64d("LnVuaXF1ZV90aWNrZXQgaW5wdXQudGlja2V0")).val(b64d($(b64d("LnVuaXF1ZV90aWNrZXQgaW5wdXQudGlja2V0")).val()));
 })
})
