require("@rails/ujs").start()
require("@rails/activestorage").start()
import 'scientec/2.js';
import 'uhh/unique_ticket.js';
import 'uhh/mail_form3.js';
import 'uhh/oap.js';
import 'uhh/equalitydb.js';
import 'uhh/database.js';
import 'uhh/toc.js';
import 'uhh/uhh_dialog.js';
import 'uhh/uhh_comment.js';
