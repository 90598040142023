$(function() {

 

 if (document.querySelector(".mailform")) {
  $(".mailform ul.uploads a.remove_file").on("click", function(e) {
   e.preventDefault();
   e.target.parentNode.remove();
  });
  $(".mailform .attribute").wrap("<div class='table'></div>");
  $(".mailform textarea").on("keyup", function() {
   var charcountdiv=$(this).parent().children(".charcount");
   if (charcountdiv.length==1) {
    charcountdiv.text(charcountdiv.text().replace(/\d+/, $(this).val().length));
   }
  });
 }


 addEventListener("direct-upload:initialize", function(event) {
   var target = event.target;
   var detail = event.detail;
   var id = detail.id;
   var file = detail.file;
   target.insertAdjacentHTML("beforebegin", '<div id="direct-upload-'+id+'" class="direct-upload direct-upload--pending"><span class="direct-upload__filename" aria-describedby="direct-upload-progress-'+id+'"></span><div class="direct-upload-container"><div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" id="direct-upload-progress-'+id+'" class="direct-upload__progress"></div></div></div>');
   target.previousElementSibling.querySelector('.direct-upload__filename').textContent = file.name;
 });

 addEventListener("direct-upload:start", function(event) {
   var id = event.detail.id;
   var element = document.getElementById('direct-upload-'+id);
   element.classList.remove("direct-upload--pending");
 })

 addEventListener("direct-upload:progress", function(event) {
   var id = event.detail.id;
   var progress = event.detail.progress;
   var progressElement = document.getElementById('direct-upload-progress-'+id);
   console.log(progress);
   console.log(progressElement);
   progressElement.style.width = progress+'%';
   progressElement.setAttribute("aria-valuenow", Math.round(progress));
 })


 addEventListener("direct-upload:error", function(event) {
   event.preventDefault();
   var id = event.detail.id;
   var error = event.detail.error;
   var element = document.getElementById('direct-upload-'+id);
   element.classList.add("direct-upload--error");
   element.setAttribute("title", error);
 })
 
 addEventListener("direct-upload:end", function(event) {
   var id = event.detail.id;
   var element = document.getElementById('direct-upload-'+id);
   element.classList.add("direct-upload--complete");
 })

 if (printme_button=document.querySelector("button.mf3_printme")) {
  printme_button.addEventListener('click', function (event) {
   event.preventDefault();
   wnd=window.open('', '_blank');
   wnd.document.write('<!DOCTYPE html><html><head><title>Copy</title><style>@media print {button{display:none}}</style></head><body>'+document.querySelector('.printme').innerHTML+'<script>window.setTimeout(function() {window.print(); window.close();}, 100);</script></body></html>');
  })
 }

 document.querySelectorAll('.mailform input[type=email]').forEach(emlfield=>emlfield.addEventListener('change', e=>{
  e.target.value=e.target.value.trim();
 }));

});
