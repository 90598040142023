//document.addEventListener("DOMContentLoaded", function(e) {
UHH.ready(()=>{
 if (document.querySelector('.spalte.links')==null) return;
 let toc=[];
 document.querySelector('.spalte.links').querySelectorAll('h2, h3, h4, h5, h6').forEach((elem)=>{
  (elem.id=='') ? elem.id='toc_'+Math.floor(Math.random()*99999) : '';
  if (elem.closest('.sprungmarken')==null && elem.closest('.outer > .standard:not(.nobox)')==null) {
   toc.push([elem.closest('.nobox')!=null, elem.nodeName[1], elem.textContent, elem.id]);
  }
 });
 document.querySelectorAll('.sprungmarken').forEach((toc_node)=>{
  let max_depth=toc_node.querySelector('.ajax .toc_depth').innerText;
  let toc_with_boxes=toc_node.querySelector('.ajax .toc_with_boxes').innerText;
  toc.forEach((entry) => {
   if (entry[1]<=max_depth && (toc_with_boxes=='true' || entry[0]==true)) toc_node.querySelector('ul').innerHTML+='<li><a href="#'+entry[3]+'">'+entry[2]+'</a></li>'
  });
 })
});
