$(function() {

 // ----- oa portal databases ---->
 $(".oadb .oap-search .oap-search-string").on("keyup", function() {
  $(".oap-result").each(function() {
   var re = new RegExp($(".oadb .oap-search .oap-search-string").val(), "i");
   if ($(this).text().match(re)){
    $(this).show();
   } else {
    $(this).hide();
   }
  });
 });
 $(".oadb .oap-result .tag").on("click", function(e) {
  e.preventDefault();
  $(".oadb .oap-search .oap-search-string").val($(this).text());
  $(".oadb .oap-search .oap-search-string").keyup();
 });
 // <----- oa portal databases ----

  
 var tags={}
 $(".equalitydb .oap-search-search .oap-search-select select").each(function() {tags[this.id]={}});
 
 for (var group in tags) {
  $(".tag[data-group="+group+"]").each(function() {
   tags[group][$(this).text()]=1
  });
 };
 
 for (var group in tags) {
  var i=0;
  $(Object.keys(tags[group]).sort(function(a,b) {return a.localeCompare(b)})).each(function() {
    $("#"+group).append("<option value='"+i+"'>"+this+"</option>");
    i++;
   }
  )
 }
 
 $(".equalitydb .oap-search-search .oap-search-select select").on("change", function(e) {
  doSearch();
 });
 $(".equalitydb .oap-search-search .oap-search-input input[type=text]").on("keyup", function(e){
  doSearch();
 });
 $(".equalitydb .oap-search-search .oap-search-input input[type=submit]").on("click", function(e){
  doSearch();
 });

 $(".equalitydb .oap-search-search .oap-search-button button.resetbutton").on("click", function() {
  $(".equalitydb .oap-search-search .oap-search-select select").each(function() {$(this).val("-1")});
  $(".equalitydb .oap-search-search .oap-search-input input[type=text]").val("");
  doSearch();
 });

 $(".equalitydb .oap-results .tag").on("click", function(e) {
  e.preventDefault();
  select_elem=$("#"+$(this).attr("data-group"));
  val=$(this).text();
  var option_id=-1;
  select_elem.find("option").each(function() {
   if ($(this).text()==val) {
    option_id=$(this).attr("value");
   }
  });
  select_elem.val(option_id);
  doSearch();
 });
 
});

function doSearch() {
 var query={}
 if ($(".equalitydb .oap-search-search .oap-search-input input[type=text]").val()!="") {query["text"]=$(".equalitydb .oap-search-search .oap-search-input input[type=text]").val()}
 $(".equalitydb .oap-search-search .oap-search-select select option:selected").each(function() {
  if($(this).val()!=-1) {
   query[$(this).parent().attr("id")]=$(this).text();
  }
 });


 $(".equalitydb .oap-results .oap-result").each(function() {
  var result=$(this);
  var matches=0;
  for (var group in query) {
   var val=query[group];
   if (group != "text") {
    result.find(".tag[data-group="+group+"]").each(function() {
     if ($(this).text()==val) {
      matches++;
     }
    });
   } else {
    terms=val.trim().split(" ");
    var all_terms_found=true;
    $.each(terms, function() {
     pattern=RegExp(this, "i");
     if (!((result.find("h2").text()+result.find(".oap-result-abstract").text()+result.find(".oap-result-hidden").text()).match(pattern))) {
      all_terms_found=false;
      return all_terms_found;
     }
    });
    if (all_terms_found) matches++;
   }
  }
  if (matches >= Object.keys(query).length) { result.show() } else { result.hide() };
 });
}
